import React from 'react';

const LandingPage = ({ onEnter, contentLoaded , isSliding}) => {
  return (
    <div className="relative h-screen w-full overflow-hidden">
      <div 
        className={`absolute inset-0 transition-transform duration-500 ease-in-out ${
          isSliding ? '-translate-y-full' : 'translate-y-0'
        }`}
      >
        <img
          src="landing.jpg"
          alt="Landing page background"
          className="w-full h-full object-cover"
        />
      </div>
      <div className={`absolute inset-x-0 bottom-1/4 flex flex-col items-center justify-center transition-opacity duration-500 ${
        isSliding ? 'opacity-0' : 'opacity-100'
      }`}>
        <button
          onClick={onEnter}
          className={`px-6 py-3 text-white border-2 border-white bg-transparent hover:bg-white hover:text-black transition-colors duration-300 text-lg font-semibold rounded ${
            contentLoaded ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
          }`}
          disabled={!contentLoaded}
        >
          Enter
        </button>
      </div>
    </div>
  );
};

export default LandingPage;