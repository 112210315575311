import { useState, useEffect } from 'react';

const useImagePreloader = (imageUrls) => {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const imagePromises = imageUrls.map(url => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        if (isMounted) {
          setImagesPreloaded(true);
        }
      })
      .catch(error => console.error("Failed to preload images", error));

    return () => {
      isMounted = false;
    };
  }, [imageUrls]);

  return imagesPreloaded;
};

export default useImagePreloader;