import './App.css';
import React, { useState, Suspense, useEffect } from 'react';
import LandingPage from './components/LandingPage';
import useImagePreloader from './hooks/useImagePreloader';
import 'ldrs/treadmill'

const InnerPage = React.lazy(() => import('./components/InnerPage'));

function App() {
  const [showLanding, setShowLanding] = useState(true);
  const [isSliding, setIsSliding] = useState(false);
  const [isInnerPageLoaded, setIsInnerPageLoaded] = useState(false);
  
  const imagesToPreload = [
    'banner2.png',
    'IMG_5448.jpg',
    // Add other image URLs here
  ];

  const imagesPreloaded = useImagePreloader(imagesToPreload);

  useEffect(() => {
    import('./components/InnerPage').then(() => {
      setIsInnerPageLoaded(true);
    });
  }, []);

  const handleEnter = () => {
    if (imagesPreloaded && isInnerPageLoaded) {
      setIsSliding(true);
      setTimeout(() => {
        setShowLanding(false);
      }, 500); // Adjust this timing to match your slide animation duration
    }
  };

  return (
    <div className="App relative overflow-hidden">
      {(showLanding || isSliding) && (
        <LandingPage 
          onEnter={handleEnter} 
          contentLoaded={imagesPreloaded && isInnerPageLoaded} 
          isSliding={isSliding} 
        />
      )}
      <Suspense fallback={
        <div className="h-screen w-full flex items-center justify-center bg-white">
          <l-treadmill size="70" speed="1.25" color="black"></l-treadmill>
        </div>
      }>
        
        <div className={`page-container inner ${isSliding ? 'sliding' : ''}`}>
          <InnerPage />
        </div>
      </Suspense>
    </div>
  );
}

export default App;